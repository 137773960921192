/* Breakpoint */

$mediumBreakPoint: 675px;
$wideBreakPoint: 975px;

/* Color */

$primary: #ff8a00;
$secondary: #a12139;
$text: #525252;
$mention: #838383;
$dark: #525252;
$medium: #f7f7f7;
$light: #fff;

/* Font */

$pictoFont: "icomoon";
$defaultFont: "Muli",
sans-serif;
$altFont: "News Cycle",
sans-serif;

/* Measurments */

$mobileMargin: 18px;
$footerMaxWidth: 896px;
$contentMaxWidth: 1266px;