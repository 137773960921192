/* Basics */

*,
::before,
::after {
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

html,
body {
    margin: 0;
    padding: 0;
}

body {
    // min-width: $contentMinWidth;
}

img {
    max-width: 100%;
    height: auto;
}

p {
    margin: 1.5em 0;
}


/* Links & Button */

a {
    text-decoration: none;
    &:hover {
        text-decoration: none;
    }
}

button {
    -webkit-tap-highlight-color: transparent;
}

.toggler {
    position: fixed;
    opacity: 0;
}