.wordpress {
    >.inner {
        z-index: 0;
        position: relative;
        color: $light;
        margin: 60px 0 44px;
        padding: 58px ($mobileMargin*2) 178px;
        &:after {
            content: '';
            z-index: -1;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: url(../img/dark-matter_wordpress.png) $secondary;
        }
        >p:first-child {
            &:before,
            &:after {
                content: '';
                position: absolute;
            }
            &:before {
                top: -30px;
                left: 50%;
                transform: translate(-50%, 0);
                width: 85px;
                height: 85px;
                background: url(../img/wordpress-logo.png) no-repeat top /contain;
            }
            &:after {
                bottom: -34px;
                left: 50%;
                transform: translate(-50%, 0);
                width: 320px;
                height: 225px;
                background: url(../img/mac_charte_tpe.png) no-repeat bottom /contain;
            }
        }
    }
}