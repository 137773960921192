@media (min-width: $wideBreakPoint) {
	.included-services {
		margin: 0 auto;
		padding: 0 $mobileMargin;
		max-width: $contentMaxWidth;
		.intro-label {
			font-size: 15px;
		}
		.title {
			font-size: 50px;
		}
	}
	.service-list {
		> li {
			width: 25%;
			&:before {
				font: 400 40px/70px $pictoFont;
				margin: 8px;
				width: 70px;
				border-radius: 35px;
			}
    }
    .host {
      &:before {
        font-size: 38px;
      }
    }
    .backup {
      &:before {
        font-size: 37px;
      }
    }
    .support-247 {
      &:before {
        font-size: 36px;
      }
    }
  
	}
	.optional-services {
		margin: 105px 0 65px;
		> .inner {
			z-index: 0;
			position: relative;
			margin: auto;
			padding: $mobileMargin $mobileMargin 1px;
			max-width: $contentMaxWidth;
			&:before {
				content: "";
				position: absolute;
				top: 50%;
				right: 75%;
				transform: translate(0, -50%);
				display: block;
				width: 392px;
				height: 454px;
				background: url(../img/mobile_charte_tpe.png) no-repeat left / contain;
			}
		}
		.col {
			margin: 0 0 0 26%;
			width: 74%;
		}
		.intro-label {
			font-size: 15px;
		}
		.title {
			font-size: 50px;
		}
	}
	.option-list {
		margin: 12px (-$mobileMargin) 0;
		display: flex;
		flex-wrap: wrap;
		> dt,
		> dd {
			width: 33.333%;
			padding: 0 $mobileMargin;
		}
		> dt {
			order: -1;
			font-size: 20px;
		}
	}
}
