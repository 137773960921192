.first-call {
	position: relative;
	padding: 0 $mobileMargin;
	> .title {
		position: absolute;
		top: -95px;
		left: 40%;
		margin: 0;
		font: 800 48px/0.9em $defaultFont;
		text-indent: -70px;
		color: #fff;
		white-space: nowrap;
		&:after {
			content: "\e903";
			font: 400 10px $pictoFont;
			vertical-align: 1.3em;
			margin-left: 10px;
		}
	}
}

.recall {
	margin: -158px auto 0;
	padding: 1px $mobileMargin;
	background: $light;
	box-shadow: 0 0 5px rgba($dark, 0.125);
	max-width: 600px;
	> .title {
		margin: $mobileMargin 0 0;
		font: 700 22px/1em $defaultFont;
	}
}

.intro {
	margin: $mobileMargin;
	text-align: center;
	> p {
		text-align: left;
	}
	> .title {
		margin: 0;
		font: 900 29px/1em $defaultFont;
		color: $primary;
	}
	.mention {
		display: block;
		font: 400 italic 11px $defaultFont;
	}
	.button {
		margin-right: auto;
		margin-left: auto;
	}
}

.advantage-list,
.advantage-list > li {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.advantage-list {
	margin: $mobileMargin * 2 0;
	text-align: center;
	font: 700 17px $defaultFont;
	color: $secondary;
	> li {
		margin: $mobileMargin 0;
		padding: 0 $mobileMargin;
		&:before {
			display: block;
			margin: 6px 0;
			font: 400 30px $pictoFont;
		}
	}
	.available-247 {
		&:before {
			content: "\e908";
			font-size: 22px;
		}
	}
	.acquire-clients {
		&:before {
			content: "\e907";
		}
	}
	.enlarge-offer {
		&:before {
			content: "\e90e";
		}
	}
	.better-customer-care {
		&:before {
			content: "\e90c";
		}
	}
}

/* Medium query */

@media (min-width: $mediumBreakPoint) {
	.advantage-list {
		display: flex;
		flex-wrap: wrap;
		> li {
			margin-top: 0;
			width: 50%;
		}
	}
}
