@font-face {
    font-family: 'icomoon';
    src: url('../font/icomoon.woff?201910160924') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-picto_assistance:before {
    content: "\e900";
}

.icon-picto_astreinte:before {
    content: "\e901";
}

.icon-picto_contact_rapide:before {
    content: "\e902";
}

.icon-picto_flche:before {
    content: "\e903";
}

.icon-picto_flche_retour:before {
    content: "\e904";
}

.icon-picto_graphisme:before {
    content: "\e905";
}

.icon-picto_hbergement:before {
    content: "\e906";
}

.icon-picto_nouveaux_clients:before {
    content: "\e907";
}

.icon-picto_oeil:before {
    content: "\e908";
}

.icon-picto_outil_administration:before {
    content: "\e909";
}

.icon-picto_rgpd:before {
    content: "\e90a";
}

.icon-picto_sauvegarde:before {
    content: "\e90b";
}

.icon-picto_service_client:before {
    content: "\e90c";
}

.icon-picto_ssl:before {
    content: "\e90d";
}

.icon-picto_zone_chalandise:before {
    content: "\e90e";
}