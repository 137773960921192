.main-header {
	z-index: 0;
	position: relative;
	height: 374px;
	background: url(../img/banner.jpg) no-repeat center / cover;
	> .inner {
		position: relative;
	}
	.logo {
		position: absolute;
		top: 0;
		left: $mobileMargin;
		font-size: 0;
		width: 150px;
		height: 85px;
		background: url(../img/a3web.svg) rgba($light, 0.7) no-repeat center / 90% auto;
		box-shadow: 0 0 15px rgba($dark, 0.25);
	}
	.back {
		position: absolute;
		top: $mobileMargin;
		right: $mobileMargin;
		font: 700 italic 12px $defaultFont;
		color: $light;
		width: 100px;
		text-align: right;
		&:before {
			content: "\e904";
			font: 400 2em $pictoFont;
			float: left;
		}
	}
	&:after {
		content: "";
		z-index: -1;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+2,000000+100&0+0,1+50,0+100 */
		background: -moz-linear-gradient(
			top,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.04) 2%,
			rgba(0, 0, 0, 1) 50%,
			rgba(0, 0, 0, 0) 100%
		);
		/* FF3.6-15 */
		background: -webkit-linear-gradient(
			top,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.04) 2%,
			rgba(0, 0, 0, 1) 50%,
			rgba(0, 0, 0, 0) 100%
		);
		/* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.04) 2%,
			rgba(0, 0, 0, 1) 50%,
			rgba(0, 0, 0, 0) 100%
		);
		/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#00000000', GradientType=0);
		/* IE6-9 */
		opacity: 0.4;
	}
}

.phone-button {
	cursor: pointer;
	z-index: 1;
	position: fixed;
	bottom: 130px;
	right: 10px;
	width: 50px;
	height: 50px;
	text-align: center;
	border-radius: 25px;
	background: $secondary;
	border: 3px solid $light;
	box-shadow: 0 0 10px rgba($dark, 0.25);
	transform: scale(0);
	transition: all 300ms cubic-bezier(0.49, -0.49, 0.5, 0.51) 300ms;
	&:before {
		position: relative;
		z-index: 2;
		content: "\e902";
		font: 400 25px/45px $pictoFont;
		color: $light;
	}
	&:after {
		z-index: 1;
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: 35px;
		background: $secondary;
	}
	&.show {
		transform: scale(1);
		transition-timing-function: cubic-bezier(0.5, 0.5, 0.46, 1.53);
		transition-delay: 0ms;
	}
	> span {
		z-index: -1;
		position: absolute;
		top: 50%;
		right: 35px;
		padding: 0;
		max-width: 0;
		background: $light;
		border-radius: 35px 0 0 35px;
		white-space: nowrap;
		overflow: hidden;
		color: $secondary;
		font: 700 18px/70px $defaultFont;
		opacity: 0;
		transform: translate(0, -50%);
		text-shadow: 0 0 5px rgba($light, 0.7);
		white-space: nowrap;
		transition: all 300ms ease;
		box-shadow: 0 0 10px rgba($dark, 0.125);
	}
}

.call-button {
	cursor: pointer;
	z-index: 1;
	position: fixed;
	bottom: 65px;
	right: 10px;
	width: 50px;
	height: 50px;
	text-align: center;
	border-radius: 25px;
	background: $primary;
	border: 3px solid $light;
	box-shadow: 0 0 10px rgba($dark, 0.25);
	transform: scale(0);
	transition: all 300ms cubic-bezier(0.49, -0.49, 0.5, 0.51) 150ms;
	&:before {
		content: "\e90f";
		font: 400 20px/45px $pictoFont;
		color: $light;
	}
	&.show {
		transform: scale(1);
		transition-timing-function: cubic-bezier(0.5, 0.5, 0.46, 1.53);
		transition-delay: 150ms;
	}
}

.top-button {
	cursor: pointer;
	z-index: 1;
	position: fixed;
	bottom: 10px;
	right: 10px;
	width: 50px;
	height: 50px;
	text-align: center;
	transform: scale(0);
	transition: all 300ms cubic-bezier(0.49, -0.49, 0.5, 0.51);
	&:before {
		content: "\e903";
		font: 400 17px/45px $pictoFont;
		display: block;
		color: $dark;
		transform: rotate(-90deg);
	}
	&.show {
		transform: scale(1);
		transition-timing-function: cubic-bezier(0.5, 0.5, 0.46, 1.53);
		transition-delay: 300ms;
	}
}
