body {
  color: $text;
  font: 400 15px $defaultFont;
}

strong {
  font-weight: 900;
}


/* Form elements */

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  margin: 14px 0;
  padding: 10px 25px;
  border: 1px solid $dark;
  font: 600 italic 1em/1em $defaultFont;
}

input.checkbox {
  position: absolute;
  opacity: 0;
  &:checked {
    +label.checkbox {
      &:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        width: 7px;
        height: 7px;
        background: $dark;
      }
    }
  }
}

label.checkbox {
  cursor: pointer;
  z-index: 0;
  position: relative;
  display: block;
  margin: 14px 0;
  padding: 0 0 0 $mobileMargin;
  color: $mention;
  font: 600 italic 10px $defaultFont;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 13px;
    height: 13px;
    border: 1px solid $dark;
  }
}

.button,
button,
input[type="button"],
input[type="submit"] {
  cursor: pointer;
  display: inline-block;
  margin: 14px 0;
  padding: 9px 30px 11px;
  font: 700 20px/1em $altFont;
  color: $light;
  background: $primary;
  border: 0;
  box-shadow: 0 0 10px rgba($dark, 0.125);
}

.grecaptcha-badge{
  opacity: 0.0125;
}