/* Modal */
.modal {
	cursor: pointer;
	z-index: 4;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	background: rgba($light, 0.7);
	transition: all 300ms ease;
	display: flex;
	align-items: center;
	justify-content: center;
	.closer {
		cursor: pointer;
		position: absolute;
		top: 15px;
		right: 0;
		width: 40px;
		height: 40px;
		background: rgba(255, 255, 255, 0.1);
		&:before,
		&:after {
			cursor: pointer;
			content: "";
			position: absolute;
			width: 16px;
			height: 2px;
			background: $dark;
			top: 18px;
			right: 12px;
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}
	&.show {
		opacity: 1;
	}
	> .inner {
		&:before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			height: 15px;
			background: url(../img/dark-matter_wordpress.png) $primary;
		}
		cursor: default;
		position: relative;
		background: $light;
		padding: 60px 30px;
		max-width: 85%;
		max-height: 85%;
		text-align: center;
		overflow: hidden;
		box-shadow: 0 0 10px rgba($dark, 0.25);
		> strong {
			font: 700 22px/1em $defaultFont;
			display: block;
		}
	}
}
