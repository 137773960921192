@media (min-width: $wideBreakPoint) {
	.footer {
		> .inner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: auto;
			max-width: $footerMaxWidth;
			.logo {
				margin: 0 auto 0 0;
				min-width: 168px;
			}

			.address,
			.phone,
			.web {
				margin: 0;
			}

			.address {
				width: 35%;
			}
			.phone {
				position: relative;
				width: 20%;
				&:before,
				&:after {
					content: "-";
					position: absolute;
					top: 50%;
					transform: translate(0, -50%);
				}
				&:before {
					left: 0;
				}
				&:after {
					right: 0;
				}
			}
			.web {
				width: 20%;
			}
		}
	}
}
