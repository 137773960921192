.included-services {
  margin: 44px $mobileMargin*2 35px;
  .intro-label {
    &:before {
      content: '';
      margin: 0 10px 0 0;
      display: inline-block;
      vertical-align: middle;
      width: 17px;
      height: 2px;
      background: $secondary;
    }
    font:400 13px $altFont;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }
  .title {
    margin: 10px 0;
    font: 700 32px/1em $defaultFont;
    color: $secondary;
    &:after {
      content: '\e903';
      font: 400 0.3em $pictoFont;
      display: inline-block;
      color: $secondary;
      margin: 0 0 0 1em;
      vertical-align: middle;
    }
  }
}

.service-list,
.service-list>li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.service-list {
  >li {
    margin: 20px 0;
    &:before {
      content: '';
      display: block;
      font: 400 26px/40px $pictoFont;
      color: $secondary;
      margin: 8px;
      width: 40px;
      text-align: center;
      background: $light;
      border-radius: 20px;
      box-shadow: 0 0 5px rgba($dark, 0.25);
      transition: all 300ms ease;
    }
    &:hover {
      &:before {
        background-color: $secondary;
        color: $light;
      }
    }
  }
  .design {
    &:before {
      content: '\e905';
    }
  }
  .rgpd {
    &:before {
      content: '\e90a';
    }
  }
  .support {
    &:before {
      content: '\e900';
    }
  }
  .integration {
    &:before {
      content: '\e909';
    }
  }
  .host {
    &:before {
      content: '\e906';
      font-size: 23px;
    }
  }
  .backup {
    &:before {
      content: '\e90b';
      font-size: 22px;
    }
  }
  .support-247 {
    &:before {
      content: '\e901';
      font-size: 21px;
    }
  }
  .secure {
    &:before {
      content: '\e90d';
    }
  }
}

.optional-services {
  margin: 32px 0;
  background: url(../img/binding-light_option.png);
  >.inner {
    padding: $mobileMargin $mobileMargin*2 1px;
  }
  .intro-label {
    &:before {
      content: '';
      margin: 0 10px 0 0;
      display: inline-block;
      vertical-align: middle;
      width: 17px;
      height: 2px;
      background: $primary;
    }
    font:400 13px $altFont;
    letter-spacing: 0.08em;
    text-transform: uppercase;
  }
  .title {
    margin: 10px 0;
    font: 700 32px/1em $defaultFont;
    color: $primary;
    &:after {
      content: '\e903';
      font: 400 0.3em $pictoFont;
      display: inline-block;
      color: $primary;
      margin: 0 0 0 1em;
      vertical-align: middle;
    }
  }
}

.option-list,
.option-list>dt,
.option-list>dd {
  margin: 0;
  padding: 0;
  display: block;
}

.option-list {
  margin: 45px 0 0;
  >dt {
    &:before {
      content: '+ ';
    }
    font:900 16px $defaultFont;
    margin: 25px 0 10px;
  }
  >dd {
    margin: 10px 0 25px;
  }
}


/* Medium query */

@media (min-width:$mediumBreakPoint) {
  .service-list {
    display: flex;
    flex-wrap: wrap;
    margin: $mobileMargin (-$mobileMargin);
    >li {
      width: 50%;
      margin: 0;
      padding: $mobileMargin/2 $mobileMargin;
    }
  }
}