@media (min-width: $wideBreakPoint) {
	.first-call {
		margin: auto;
		max-width: $contentMaxWidth;
		> .title {
			top: -225px;
			left: 22%;
			font-size: 92px;
			text-indent: -138px;
			&:after {
				font-size: 19px;
				vertical-align: 1.4em;
				margin: 0 0 0 26px;
			}
		}
		&:after {
			content: "";
			display: block;
			clear: both;
		}
	}
	.recall {
		margin: -400px 0 0;
		padding: 20px 50px;
		float: right;
		width: 50%;
		> .title {
			font-size: 40px;
			margin: 10px 0 30px;
		}
	}
	.intro {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		padding: $mobileMargin;
		width: 50%;
		> .title {
			font: 700 45px $defaultFont;
		}
		.mention {
			font: 400 italic 15px $defaultFont;
		}
	}
	.advantage-list {
		clear: right;
		float: right;
		width: 50%;
		max-width: 600px;
		font-size: 20px;
		> li {
			padding: 0 $mobileMargin * 2;
		}
	}
}
