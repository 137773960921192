@media (min-width:$wideBreakPoint) {
  /* Form elements */
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    margin: 20px 0;
    padding: 12px 30px;
  }
  label.checkbox {
    font-size: 15px;
  }
  .button,
  button,
  input[type="button"],
  input[type="submit"] {
    margin: 20px 0;
    padding: 11px 38px 14px;
    font-size: 25px;
  }
}