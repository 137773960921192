@media (min-width: $wideBreakPoint) {
	.main-header {
		height: 576px;
		> .inner {
			margin: auto;
			max-width: $contentMaxWidth;
		}
		.logo {
			width: 235px;
			height: 130px;
			background-size: 90% auto;
		}
		.back {
			width: auto;
			font: 700 18px $defaultFont;
			&:before {
				font-size: 18px;
				float: none;
				margin: 0 10px 0 0;
			}
		}
		&:after {
			/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,1+100 */
			background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
			/* FF3.6-15 */
			background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
			/* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
			/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000', GradientType=0);
			/* IE6-9 */
		}
	}

	.phone-button {
		bottom: 180px;
		width: 70px;
		height: 70px;
		border-radius: 35px;
		border: 4px solid $light;
		&:before {
			font: 400 33px/62px $pictoFont;
		}
		&:hover {
			> span {
				max-width: 250px;
				padding: 0 50px 0 24px;
				opacity: 1;
			}
		}
	}

	.call-button {
		bottom: 85px;
		width: 70px;
		height: 70px;
		border-radius: 35px;
		border: 4px solid $light;
		&:before {
			font: 400 28px/62px $pictoFont;
		}
	}
}
