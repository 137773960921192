.footer {
	background: $medium;

	> .inner {
		padding: 24px 0;
		text-align: center;
        .address,
        .phone,
        .web {
			color: $dark;
			display: block;
			margin: 8px 0 0;
		}

		.logo {
			display: block;
			margin: 0 auto 24px;
			font-size: 0;
			width: 168px;
			height: 60px;
			background: url(../img/a3web.svg) no-repeat center / 79% auto;
		}
	}
}
