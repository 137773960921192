@media (min-width:$wideBreakPoint) {
  .wordpress {
    overflow: hidden;
    margin: -25px 0 -15px;
    padding: 25px 0 15px;
    width: 100%;
    >.inner {
      margin: 30px auto 40px;
      max-width: $contentMaxWidth;
      padding: 90px 0 50px $mobileMargin;
      min-height: 261px;
      &:after {
        left: -9999em;
        right: 180px;
        // transform: skew(20deg);
      }
      >p:first-child {
        width: 50%;
        &:before {
          top: -50px;
          left: $mobileMargin;
          transform: none;
          width: 112px;
          height: 112px;
        }
        &:after {
          top: 50%;
          right: -75px;
          bottom: auto;
          left: auto;
          transform: translate(0, -50%);
          width: 532px;
          height: 364px;
        }
      }
    }
  }
}