@media (min-width:$wideBreakPoint) {
  .meet-us {
    padding: 0 $mobileMargin;
    margin: 64px auto 36px;
    max-width: $contentMaxWidth;
    background-position: right;
    background-size: 48% auto;
    .title {
      font-size: 40px;
    }
  }
  .meet-us-form {
    width: 48%;
    .fieldset {
      height: auto;
      overflow: hidden;
    }
    input[type="text"],
    input[type="tel"],
    input[type="email"] {
      float: left;
      width: 47%;
      margin-top: 0;
      &:nth-of-type(2n) {
        margin-left: 3%;
      }
    }
  }
}